<script setup>
    import { initNavBar } from 'o365.controls.NavBar.js';
    
    const props = defineProps({
        contextWhereClause: {
            type: String
        },
        hideOrgUnitsTree: { 
            type: Boolean,
            default: false,
        },
        operationMode: {
            type: Boolean,
            default: false
        },
        domainMode: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        }
    });

    initNavBar({ 
        operationMode: props.operationMode, 
        contextWhereClause: props.contextWhereClause, 
        hideOrgUnitsTree: props.hideOrgUnitsTree,
        domainMode: props.domainMode,
        title:props.title
    }); 

    // console.warn('Navbar component is not in use anymore. We are using a common navbar in ct and nt now.')
    /*
    import { ref } from 'vue';
    import OMegaMenu from 'o365.vue.components.navbar.MegaMenu.vue';
    import OHomeButton from 'o365.vue.components.navbar.HomeButton.vue';
    import OContextSelector from 'o365.vue.components.navbar.ContextSelector.vue';
    import OBreadcrumb from 'o365.vue.components.navbar.Breadcrumb.vue';
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
    import OMatchMedia from 'o365.vue.components.MatchMedia.vue';
    import ONavbarActions from 'o365.vue.components.navbar.Actions.vue';
    import context from 'o365.modules.Context.ts';
    
    import { hasQueryParameter } from 'o365.modules.utils.url.ts';
    const showNavbar = ref(true);
    defineProps({
        contextWhereClause:{
            type:String
        },
        hideOrgunitsTree:{
            type:Boolean,
            default:false
        }
    })

    const orgUnitRef = ref(context.orgUnit);

    if(hasQueryParameter('hidenavbar') || hasQueryParameter('HideNav')){
        showNavbar.value = false;
    }

    context.onChanged(({ orgUnit }) => {
        orgUnitRef.value = orgUnit;
    });
    */

</script>

<template>
    <!--
    <OMediaQueryProvider>
        <OMatchMedia v-slot="{ isMobileOrTablet }">
            <nav id="o365-navbar" class="navbar navbar-expand navbar-dark bg-primary p-1 sticky-top" v-if="showNavbar">
                <div class="navbar-nav flex-row w-100">
                    <div class="nav-item">
                        <OMegaMenu></OMegaMenu>
                    </div>
                    
                    <div class="nav-item">
                        <OHomeButton></OHomeButton>
                    </div>
                    
                    <div class="nav-item nav-link active overflow-hidden">
                        {{ orgUnitRef }}
                    </div>
                    
                    <OBreadcrumb v-if="!isMobileOrTablet">
                        <template v-if="$slots.apptitle" #apptitle>
                            <slot name="apptitle"></slot>
                        </template>
                    </OBreadcrumb>

                    <div class="nav-item nav-link active ms-auto flex-row">
                        <OContextSelector :whereClause="contextWhereClause" :hideOrgunitsTree="hideOrgunitsTree"></OContextSelector>
                    </div>

                    <template v-if="!isMobileOrTablet">
                        <ONavbarActions></ONavbarActions>
                    </template>
                    <div class="dropdown ms-auto" v-else>
                        <a href="#" type="button" class="nav-link active" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside">
                            <i class="bi bi-three-dots-vertical"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end text-nowrap">
                            <ONavbarActions showTitle></ONavbarActions>
                        </div>
                    </div>
                </div>
            </nav>
        </OMatchMedia>
    </OMediaQueryProvider>
    -->
</template>
<style scoped>
    /*
    #o365-navbar {
        background-color: #4682b4;
        height: 49px;
    }

    .bi:hover {
        -webkit-text-stroke: 1px;
    }

    .megamenu-actions >>> .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x);
    }

    .dropdown .btn-secondary {
        margin-top: 10px;
        background-color: transparent;
        border: none;
    }

    .dropdown {
        padding-left: 0;
        --bs-navbar-active-color: var(--bs-emphasis-color);
    }
    */
</style>
